.features {
 position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
  .section__title {
    
      @include media("<=430px") {
        font-size: 40px;
      }
  }
}

.features__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=810px") {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  @include media("<=430px") {
    
  }
}

.features__content {
  flex: 0 1 100%;
  max-width: 791px;
  padding: 45px 0 44px;
  background-color: #ffffff;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
  overflow: hidden;
  @include media("<=1080px") {
    padding: 45px 15px 44px;
  }
  @include media("<=810px") {
    padding: 40px 15px;
    width: 100%;
  }
  @include media("<=430px") {
    flex-direction: column;
  }
}

.features__slider {
  margin-top: 22px;
  max-width: 504px;
  width: 100%;
  height: 262px;
  position: relative;
  padding-bottom: 52px;
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
    margin-top: 20px;
  }

  .slick-list {
    overflow: hidden;
    padding: 0 !important;
    height: 210px !important;

    @include media("<=810px") {
     
    }
    @include media("<=430px") {
     
    }
  }

  .slick-track {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @include media("<=810px") {
        
      }
  }

  .slider__item {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 22px;
    @include media("<=810px") {
      
    }
  }

  .features__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    padding: 22px;
    border-radius: 50px;
      background: #FFB;
      column-gap: 18px;
    @include media("<=1080px") {}

    @include media("<=430px") {
      height: 100px;
      padding: 18px;
    }
  }

  .slick-slide {
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
  }

  .features__num {
    color: #DD00B8;
    font-size: 40px;
    font-family: 'AppetitePro';
    max-width: 21px;
    flex: 0 1 100%;
    text-align: center;
    font-weight: 900;
  }

.features__text {
  font-size: 16px;
  line-height: 1.2;
  color: #5364E2;
  @include media("<=1080px") {
    font-size: 14px;
  }
  @include media("<=810px") {
    font-size: 16px;
  }
    @include media("<=430px") {
      font-size: 14px;
    }
}

.slick-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 100%;
  max-width: 29px;
  max-height: 32px;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    filter: drop-shadow(0px 2px 2px #ec97bd);
  }

  @include media("<=810px") {
     
        
      
  }

  @include media("<=430px") {}

  @include media("<=375px") {}

  @include media("<=320px") {}

  &.slick-prev {
    display: none;

    @include media("<=810px") {
        
      }
    
      @include media("<=430px") {
        
      }

    @include media("<=320px") {}
  }

  &.slick-next {

    background-image: url(../images/down.png);
    bottom: 0;

    @include media("<=810px") {
        
      }
    
      @include media("<=430px") {
        
      }

    @include media("<=320px") {}
  }
}
}

.features__image {
  max-width: 658px;
  flex: 0 1 100%;
  margin-top: -56px;
  @include media("<=1080px") {
    
  }
    @include media("<=810px") {
      margin-top: 0;
      margin-bottom: -30px;
    }
    @include media("<=430px") {
     
    }
  img {
    width: 100%;
    display: block;
    @include media("<=1080px") {
      width: 655px;
    }
         @include media("<=810px") {
          width: 100%;
         }
  }
}