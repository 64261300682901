@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap');

@font-face {
  font-family: 'AppetitePro';
  src: url('../fonts/AppetitePro.eot');
  src: url('../fonts/AppetitePro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AppetitePro.woff') format('woff'),
    url('../fonts/AppetitePro.ttf') format('truetype'),
    url('../fonts/AppetitePro.svg#AppetitePro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AppetitePro';
  src: url('../fonts/AppetitePro-Bold.eot');
  src: url('../fonts/AppetitePro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AppetitePro-Bold.woff') format('woff'),
    url('../fonts/AppetitePro-Bold.ttf') format('truetype'),
    url('../fonts/AppetitePro-Bold.svg#AppetitePro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}