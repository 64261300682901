.info {
  overflow: hidden;
  padding: 33px 0 66px;
  position: relative;
    .button {
      margin: 0 auto;
    }
}

.info__inner {
  padding-top: 44px;
  padding-bottom: 44px;
  position: relative;
  width: 100%;
  max-width: 943px;
  margin: 0 auto;
  display: block;
  @include media("<=810px") {
    
  }
    @include media("<=430px") {
      
    }

    .slick-list {
      
      padding: 0 !important;
      
  
      @include media("<=810px") {
        
      }
  
      @include media("<=430px") {}
    }
  
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 77px;
      @include media("<=810px") {
        flex-direction: row;
      }
  
      @include media("<=430px") {
        column-gap: 30px;
      }
    }
}

.info__item {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 50px;
    background: #FFB;
  text-align: center;
  max-width: 380px;
  height: 160px;
  justify-content: center;
  column-gap: 30px;
  padding: 5px;
  @include media("<=810px") {
    column-gap: 10px;
  }
  &::after {
   content: '';
   position: absolute;
   right: -65px;
   width: 30px;
   height: 30px;
   background-image: url(../images/triangle.png);
   background-position: center;
   background-repeat: no-repeat;
  }
}

.info__num {
  color: #DD00B8;
  font-size: 40px;
  font-family: 'AppetitePro';
  max-width: 21px;
  flex: 0 1 100%;
  text-align: center;
  font-weight: 900;
}

.info__text {
  color: #5364E2;
  font-size: 16px;
  font-weight: 500;
  max-width: 264px;
  @include media("<=430px") {
  
  }
}

.info__image {
  max-width: 1024px;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }
}

.info__pic {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 39vw;
}