.gallery {
  padding: 27px 0 50px;

  overflow: hidden;
    
}

.gallery__slider {
  // max-width: 1524px;
  position: relative;
  margin: 0 auto;
  flex: 0 1 100%;
  z-index: 3;
  max-width: 981px;
  padding-top: 35px;
  @include media("<=1280px") {}

  @include media("<=1024px") {}

  @include media("<=810px") {}

  @include media("<=430px") {
    max-width: 280px;
  }

  @include media("<=320px") {}
    .slick-list {
      overflow: hidden;
      padding: 0 !important;
      @include media("<=430px") {
        padding: 0 !important;
      }
    }
  
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
  
      @include media("<=430px") {}
    }

        .slick-slide {
          position: relative;
          cursor: pointer;
          transition: all 0.3s linear;
    
          img {
            text-align: center;
            width: 100%;
          }
        }

        .slick-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          font-size: 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center;
          width: 100%;
          max-width: 102px;
          max-height: 102px;
          height: 100%;
          border: none;
          background-color: transparent;
          cursor: pointer;
          transition: all 0.3s linear;
    
          &:hover {
            filter: drop-shadow(0px 2px 2px #ec97bd);
          }
    
          @include media("<=810px") {
            max-width: 82px;
            max-height: 82px;
            right: auto;
          }
    
          @include media("<=430px") {}
    
          @include media("<=375px") {}
    
          @include media("<=320px") {}
    
          &.slick-prev {
            background-image: url(../images/prev.png);
              left: -55px;
    
            @include media("<=810px") {
              
            }
    
            @include media("<=430px") {
              
            }
    
            @include media("<=320px") {}
          }
    
          &.slick-next {
             background-image: url(../images/next.png);
              right: -55px;
    
            @include media("<=810px") {
              
            }
    
            @include media("<=430px") {
              
            }
    
            @include media("<=320px") {}
          }
        }
}



.gallery__item {

  max-width: 311px;
  @include media("<=1080px") {
    
  }
}

