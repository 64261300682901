.reviews {
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    padding-top: 30px;
    @include media("<=810px") {
      
    }
    
}

.reviews__inner {
    display: flex;
    justify-content: space-around;
    padding-top: 42px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
    }
}

.reviews__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 440px;
    flex: 0 1 100%;
    @include media("<=810px") {
        position: relative;
        left: auto;
        transform: none;
    }
    img {
        width: 100%;
        display: block;
    }
}

.reviews__content {
width: 100%;
    @include media("<=810px") {
        
    }
}

.reviews__slider {
    z-index: 5;
    width: 100%;
    position: relative;
    @include media("<=810px") {
        height: auto;
        padding-right: 0;
        padding-bottom: 30px;
        max-width: 450px;
        margin: 0 auto;
        display: block;
    }
  @include media("<=430px") {
    padding-bottom: 10px;
  }
    .slick-list {
        overflow: hidden;
        padding: 0 !important;
       

        @include media("<=810px") {

        }
          @include media("<=430px") {
            
          }
    }

    .slick-track {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media("<=810px") {
            flex-direction: row;
        }
          @include media("<=430px") {
            column-gap: 10px;
          }
    }

    .reviews__slide {
        max-width: 312px;
            width: 100%;
    }

    .reviews__item {
        max-width: 312px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 22px;
        @include media("<=1080px") {}

        @include media("<=430px") {
            height: auto;
            margin: 0 auto;
            padding: 20px 15px;
        }
    }

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
    }

    .reviews__rate {
        max-width: 105px;
    }

.reviews__title {
    font-family: 'AppetitePro';
    color: #5364E2;
    font-weight: 700;
    line-height: 1.3;
    font-size: 20px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    @include media("<=430px") {
        font-size: 18px;
    }
}

.reviews__text {
    color: #000;
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 16px;
    @include media("<=430px") {
        font-size: 16px;
    }
}

.slick-arrow {
    position: absolute;
    right: 0;
    z-index: 10;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    max-width: 30px;
    max-height: 30px;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        filter: drop-shadow(0px 2px 2px #ec97bd);
    }

    @include media("<=810px") {
       
    }

    @include media("<=430px") {}

    @include media("<=375px") {}

    @include media("<=320px") {
       
    }

    &.slick-prev {
        background-image: url(../images/up.png);
        top: 42%;
        @include media("<=810px") {
            background-image: url(../images/prev.png);
                top: auto;
                bottom: 0;
                left: 40%;
        }

        @include media("<=430px") {
            left: 38%;
        }

        @include media("<=320px") {}
    }

    &.slick-next {

        background-image: url(../images/down.png);
        bottom: 42%;

        @include media("<=810px") {
            background-image: url(../images/next.png);
                top: auto;
                bottom: 0;
                right: 40%;
        }

        @include media("<=430px") {
            right: 38%;
        }

        @include media("<=320px") {}
    }
}
}