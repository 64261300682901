.about {

  padding: 86px 0 139px;
  position: relative;
  overflow-x: clip;

  @include media("<=1080px") {
    padding-bottom: 80px;
  }
  @include media("<=810px") {
   margin-top: 0;
  }
  @include media("<=430px") {
    padding-bottom: 50px;
  }
  .link {
    &::after {
      content: '';
      position: absolute;
      left: 40%;
      transform: translateX(-50%);
      bottom: -45px;
      width: 432px;
      height: 118px;
      background-image: url(../images/about-pic.png);
    }
  }
}

.about__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include media("<=810px") {
    flex-direction: column;
  }
  @include media("<=430px") {
    
  }
}

.about__text {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  z-index: 2;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 1.2;
  text-align: center;
  @include media("<=1080px") {
    
    
  }
  @include media("<=810px") {
    
  }
  
  @include media("<=430px") {
    
  }
  
}

.hat {
  position: absolute;
  left: 50%;
  top: -50px;
}



