.disclaimer {
position: relative;
    @include media("<=810px") {
        
    }
        @include media("<=430px") {
           
        }
    .section__title {
        text-align: left;
        font-size: 24px;
        color: #EBFF00;
        @include media("<=430px") {
            font-size: 40px;
            text-align: center;
        }
    }
}

.disclaimer__inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
    text-transform: uppercase;
    padding: 26px 0;
    @include media("<=810px") {
        flex-direction: column;
        row-gap: 40px;
    }
  @include media("<=430px") {
    flex-direction: column;
    
  }
}

.disclaimer__content {
    flex: 0 1 100%;
    max-width: 614px;
    position: relative;
    z-index: 6;
}

.disclaimer__text {
    padding-top: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.1;
    @include media("<=430px") {
        font-size: 14px;
        margin-bottom: 20px;
    }
}

.disclaimer__image {
    flex: 0 1 100%;
    max-width: 217px;
    @include media("<=810px") {
        max-width: 400px;
        width: 100%;
    }
    img {
        width: 100%;
    }
    &-main {
        display: none;
    }
}

.disclaimer__pic {
    position: absolute;
    right: 0;
    bottom: -30px;
    z-index: 5;
    width: 41vw;
    @include media("<=810px") {
        display: none;
    }
}