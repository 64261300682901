.footer {
  padding: 38px 0 47px;
  position: relative;
  overflow-x: clip;
  @include media("<=810px") {}

  @include media("<=430px") {
    padding: 30px 0;
  }

}

.footer__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
  }
}

.footer__nav {
  padding-top: 23px;
  padding-bottom: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 32px;
  flex: 0 1 100%;
  width: 100%;
  align-items: flex-start;
  @include media("<=810px") {
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-around;
  }
}

.footer__nav-link {
  color: #5364E2;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  text-align: left;
  line-height: 1.2;
  @include media("<=810px") {
   
    text-align: center;
  }

  @include media("<=430px") {
    font-size: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footer__link {
  color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: 900;
    padding-bottom: 12px;
  @include media("<=810px") {
    align-items: center;
    padding-bottom: 15px;
  }
&:hover {
  text-decoration: underline;
}
  
}

.copyright {
  font-size: 10px;
  text-align: center;
  color: #000;
  @include media("<=810px") {
    order: 3;
  }
}

.footer__pic {
  position: absolute;
  right: 0;
  bottom: 0;
  @include media("<=810px") {
    display: none;
  }
}



