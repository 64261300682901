.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
background: linear-gradient(180deg, #5364E2 0%, rgba(255, 255, 255, 0.00) 100%);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  @include media("<=810px") {
    height: 80px;
    background: transparent;
    box-shadow: none;
  }
  @include media("<=430px") {
    height: 70px;
  }
  
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 60px;
  @include media("<=1080px") {
    column-gap: 30px;
  }
  @include media("<=810px") {
    justify-content: center;
  }
}

.header__nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 32px;
  @include media("<=810px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      position: fixed;
      height: 45%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      z-index: 20;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      background: #DD00B8;
      opacity: 0.97;
      border-radius: 0 0 30px 30px;
      .header__link {
        padding: 20px;
        margin-right: 0;
        @include media("<=430px") {
          padding: 15px;
        }
        @include media("<=330px") {
          padding: 10px;
        }
      }
    }
  }
}

.header__nav-page {
  .header__link {
    color: #ffffff;
  }
}

.header__link {
  font-size: 12px;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  color: #5364E2;
  transition: all 0.3s linear;
  position: relative;
  @include media("<=810px") {
    font-size: 16px;
    color: #ffffff;
  }
  @include media("<=430px") {
    
  }
  &:hover {
    color: #FFF500;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.12em;
  background: #F775A4;
  bottom: -7px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  max-width: 132px;
  position: relative;
  top: 14px;
  img {
    width: 100%;
    display: block;
  }
}